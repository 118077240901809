import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { Row, Col } from 'react-bootstrap';

const ZZZBegGuide: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Anomalies and Disorders</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_anomalies.webp"
            alt="Anomalies and Disorders"
          />
        </div>
        <div className="page-details">
          <h1>Anomalies and Disorders</h1>
          <h2>
            A guide for Zenless Zone Zero (ZZZ) which will help you understand
            Anomalies and Disorders.
          </h2>
          <p>
            Last updated: <strong>25/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Introduction to Anomalies and Disorders" />
        <p>
          Anomalies are status effects (debuffs) applied to enemies after
          building up elemental damage and Disorder is what happens when you
          stack another status effect on top of the first one.
        </p>
        <SectionHeader title="Anomaly Types and Effects" />
        <p>There's multiple anomaly types currently available:</p>
        <ul>
          <li>
            <strong className="Fire">Burn (Fire)</strong> - Does DoT (Damage
            over time) damage for 10 seconds.
          </li>
          <li>
            <strong className="Electric">Shock (Electric)</strong> - Does
            additional damage when you hit an enemy that's been shocked, lasts
            10 seconds and can proc up to 10 times.
          </li>
          <li>
            <strong className="Ether">Corruption (Ether)</strong> - Does
            additional damage when you hit an enemy that's been corrupted, lasts
            10 seconds and can proc up to 20 times.
          </li>
          <li>
            <strong className="Ice">Freeze (Ice)</strong> - Damages once using
            ‘’Shatter’’ and increases critical damage against frozen enemies by
            10%.
          </li>
          <li>
            <strong className="Physical">Assault (Physical)</strong> - Damages
            once using ‘’Assault’’ and increases daze buildup against enemies by
            10%.
          </li>
        </ul>
        <SectionHeader title="Anomaly Mechanics and Interactions" />
        <p>There's some things to keep in mind:</p>
        <ul>
          <li>Anomalies cannot crit,</li>
          <li>They don't accumulate Daze,</li>
          <li>They take character stats at the time of application,</li>
          <li>They are affected by enemy modifiers at the time of damage,</li>
          <li>They have internal cooldowns,</li>
          <li>They can be wasted if applied excessively.</li>
          <li>
            Character stats, buffs, and multiple characters contributing to the
            same Anomaly affect the damage calculations.
          </li>
        </ul>
        <SectionHeader title="Anomaly Application and Buildup" />
        <p>
          The stats that affect anomaly application speed are Anomaly Mastery,
          Anomaly Buildup Rate, and Anomaly Buildup Resistance. Applying the
          same Anomaly repeatedly on an enemy will gradually slow down the
          buildup speed.
        </p>
        <SectionHeader title="Anomaly Damage Calculations" />
        <p>
          There's multiple criterias that evaluate how much damage the Anomaly
          procs will do, for that i'd suggest watching Guobas video which
          explains it a lot more in detail and shows formulas.
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="bi6IJXh_-9A" />
          </Col>
        </Row>
        <p>
          Short version would be that it scales best from ATK%, PEN%, Anomaly
          Proficiency and Anomaly Master (for faster application), so if you
          want to skip all the details and just improve the damage done, then
          keep your eyes out on those stats.
        </p>
        <SectionHeader title="Anomaly DPS Builds and Considerations" />
        <p>
          Currently we don't really have anyone that would fully benefit from
          going full Anomaly build, so it's still best to keep using the normal
          crit build. There is a good possibility that in due time we will have
          units that have their kit basically built around Anomaly specifically,
          like Boothill and Firefly in HSR, who are built around ‘’Break’’.
        </p>
        <SectionHeader title="Disorder Mechanics and Damage Calculations" />
        <p>
          Now here's the main catch with anomalies, ‘’Disorder’’, it's the bread
          and butter of ZZZ. The way it works is that if you apply a 2nd status
          effect aka debuff during a 10 second window, which then applies the
          1st element disorder, so if you say applied Burn initially, and then
          added Shock, it will trigger disorder and activate ‘’Burn Disorder’’.
          For how much damage it does, this time I would definitely suggest
          checking out the video linked, but in short, it basically triggers the
          first applied elements procs instantly, like DoT explosion (so Burn,
          Shock and Ether only), and then it applies the 2nd status effect. It's
          a bit different for Freeze and Assault if you want to combine them, it
          still counts as disorder, but in this case they count as just 2 big
          hits, like ‘’Shatter’’ and ‘’Assault’’ just triggering one after
          another, which still is good, but does not bring as much damage as the
          DoT type status effects.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZBegGuide;

export const Head: React.FC = () => (
  <Seo
    title="Anomalies and Disorders (ZZZ) | Zenless Zone Zero | Prydwen Institute"
    description="A guide for Zenless Zone Zero (ZZZ) which will help you understand Anomalies and Disorders."
    game="zzz"
  />
);
